<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <div class="d-flex align-center">
        <!-- <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"
          transition="scale-transition"
          width="40"
        />

        <v-img
          alt="Vuetify Name"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="100"
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png"
          width="100"
        /> -->
      </div>

      <v-spacer></v-spacer>
      <v-btn v-if="isLogined" @click="onLogout" text>
        <span class="mr-2">登出</span>
      </v-btn>
      <v-btn v-else @click="onLogin" text>
        <span class="mr-2">登入</span>
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    isLogined: false,
  }),
  created() {
    this.checkStorageAuthData();
  },
  async mounted() {
    // if (this.$route.query.code) {
    //   let authRespos = await this.getAuthToken(this.$route.query.code);
    //   localStorage.setItem("accessToken", authRespos.access_token);
    //   this.$router.push({ name: "Call" });
    // } else {
    //   window.location.href = `https://${this.bitrix24_domain}/oauth/authorize/?client_id=${this.bitrix24_client_id}&response_type=code&redirect_uri=${this.redirect_uri}`;
    // }
  },
  methods: {
    onLogin() {
      this.$router.push({ name: "Login" });
    },
    onLogout() {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("expiredTime");
      this.$router.push({ name: "Login" });
    },
    checkStorageAuthData() {
      const accessToken = localStorage.getItem("accessToken");
      if (!accessToken) {
        return;
      }
      const expiredTime = new Date(localStorage.getItem("expiredTime") * 1000);
      const now = new Date();
      if (now >= expiredTime) {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("expiredTime");
        this.$router.push({
          name: "Login",
        });
      } else {
        this.isLogined = true;
      }
    },
  },
};
</script>
