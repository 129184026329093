<template>
  <v-container fill-height fluid justify-center>
    <v-row justify="center">
      <v-col cols="12" md="4">
        <template>
          <h2 class="text-center"></h2>
          <div>
            <v-btn block rounded large color="primary" @click="onLogin()"> 透過Bitrix24登入 </v-btn>
          </div>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';
// @ is an alias to /src
export default {
  data() {
    return {
      systemId: 'groupclass',
      apiUrl: 'https://3xcb7p2drj.execute-api.ap-northeast-1.amazonaws.com/Prod/',
    };
  },
  components: {},
  computed: {},
  async mounted() {
    if (this.$route.query.code) {
      let authRespos = await this.getAuthToken(this.$route.query.code);
      localStorage.setItem('accessToken', authRespos.access_token);
      localStorage.setItem('expiredTime', authRespos.expires);
      // window.location.href = localStorage.getItem('redirectUrl');
    }
  },
  created() {},
  destroyed() {},
  methods: {
    async onLogin() {
      window.location.href = await this.getLoginUrl();
    },
    async getAuthToken(code) {
      let res = await axios.get(
        this.apiUrl + 'OAuth/Token?code=' + code + '&systemId=' + this.systemId
      );
      console.log(res.data);
      return res.data;
    },
    async getLoginUrl() {
      let res = await axios.get(this.apiUrl + 'OAuth/Login?systemId=' + this.systemId);
      return res.data.url;
    },
  },
};
</script>

<style scoped>
</style>