import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { title: 'Home' }
  },
  {
    path: '/login',
    name: "Login",
    component: Login,
    meta: { title: '登入', allowAnonymous: true }
  },
  {
    path: '/call/:teamName',
    name: 'Call',
    meta: { title: '電話監管', allowAnonymous: true },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Call.vue')
  },
  {
    path: '/worktime',
    name: 'WorkTime',
    meta: { title: '工作時間', allowAnonymous: true },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/WorkTime.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  window.document.title = to.meta.title;
  if (to.matched.some(record => !record.meta.allowAnonymous)) {
    const accessToken = localStorage.getItem('accessToken');
    if (!accessToken) { // Token not Exist, need to Login
      next({
        path: '/login',
        // query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next()
  }
});

export default router
